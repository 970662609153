import { useAppSelector } from '../utils/hooks'
import { type PackageType, type ProductType } from '../utils/types'

export const countCart: () => number = () => {
  const totalProducts = useAppSelector((state) => state.cart).products.reduce(
    (somme, element) => {
      if (element.quantity) {
        return somme + element.quantity
      }
      return somme
    },
    0
  )
  const totalPackages = useAppSelector((state) => state.cart).packages.reduce(
    (somme, element) => {
      if (element.quantity) {
        return somme + element.quantity
      }
      return somme
    },
    0
  )
  return totalProducts + totalPackages
}

export const getTotalPointInCart = (
  productOrderedList: ProductType[],
  packageOrderedList: PackageType[]
): number => {
  const totalProducts = productOrderedList.reduce((total, product) => {
    if (!!product.totalPoints && !isNaN(product.totalPoints)) {
      return total + product.totalPoints
    } else {
      return total
    }
  }, 0)
  const totalPackages = packageOrderedList.reduce((total, pacakge) => {
    if (!!pacakge.totalPoints && !isNaN(pacakge.totalPoints)) {
      return total + pacakge.totalPoints
    } else {
      return total
    }
  }, 0)
  return totalProducts + totalPackages
}

export const checkPointInCart = (
  productOrderedList: ProductType[],
  packageOrderedList: PackageType[]
): boolean => {
  let haveProductWithZeroPoints = false
  productOrderedList.forEach((p) => {
    if (p.pointValue === 0) {
      haveProductWithZeroPoints = true
    }
  })
  packageOrderedList.forEach((p) => {
    if (p.pointValue === 0) {
      haveProductWithZeroPoints = true
    }
  })
  return haveProductWithZeroPoints
}

export default { countCart }
